import md5 from 'js-md5'
export var SearchList = [
  { labe: '通知单号', code: 'qualityCode', type: 'input' },
  { labe: '工厂', code: 'plantName', type: 'input' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '代采购确认', value: '1' },
      { label: '待供应商确认', value: '2' },
      { label: '已完成', value: '3' }
    ]
  },
  {
    labe: '单据类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '来料批不合格', value: '1' },
      { label: '生产质量异常', value: '2' }
    ]
  }
]
export var SearchData = {
  qualityCode: '',
  createDate: '',
  status: '',
  type: '',
  plant: ''
}
export var tableField = [
  { label: '通知单号', code: 'qualityCode', type: 'input', width: '' },
  { label: '数量', code: 'blqty', type: 'input', width: '' },
  {
    label: '单据类型',
    code: 'type',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.type === 1 ? '来料批不合格' : data.type === 2 ? '生产质量异常' : ''
    }
  },
  { label: '物料编号', code: 'materielCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materielName', type: 'input', width: '' },
  { label: '工厂', code: 'plantName', type: 'input', width: '' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '待采购确认' : data.status === 2 ? '待供应商确认'
        : data.status === 3 ? '已完成' : data.status === 4 ? '已结案' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]
export var tableFieldll = [ // 来料
  { label: '序号', code: '', type: 'function', width: '60', handle (index) { return index + 1 } },
  { label: '缺陷分类', code: 'defectsType', type: 'input', width: '' },
  { label: '缺陷组', code: 'defectsGroup', type: 'input', width: '' },
  { label: '缺陷细类代码', code: 'defectsCode', type: 'input', width: '' },
  { label: '缺陷描述', code: 'defectsDesc', type: 'input', width: '' }
]

export var tableFieldsc = [ // 生产
  // { label: '序号', code: '', type: 'function', width: '60', handle (index) { return index + 1 } },
  // { label: '缺陷分类', code: 'defectsType', type: 'input', width: '' },
  // { label: '缺陷组', code: 'defectsGroup', type: 'input', width: '' },
  // { label: '缺陷细类代码', code: 'defectsCode', type: 'input', width: '' },
  // { label: '缺陷描述', code: 'defectsDesc', type: 'input', width: '' },
  { label: '不良数量', code: 'badNumber', type: 'input', width: '' },
  { label: '使用数量', code: 'userNumber', type: 'input', width: '' },
  { label: '不良率', code: 'rejectRatio', type: 'input', width: '' },
  { label: '问题类型', code: 'problemTypes', type: 'input', width: '' }
]
export var tableData = []
